import { createVariablesStorage } from './storage';
const CONST_NAME = '__KARTE_BLOCKS_RESOLVED_VARIABLES';
const EVENT_NAME = 'KARTE::BLOCKS::RESOLVE_VARIABLES';
const TIMEOUT_MS = 5000;
function parseMappedVariables(mappedVariables) {
    return Object.entries(mappedVariables)
        .map(([mappedVariable, value]) => {
        const [, parsedVariationId, parsedVariableName] = mappedVariable.match(/^__([0-9a-zA-Z]+)__(.+)/) ?? [];
        return [parsedVariationId, parsedVariableName, value];
    })
        .reduce((acc, [parsedVariationId, parsedVariableName, value]) => {
        if (!parsedVariationId || !parsedVariableName)
            return acc;
        if (!acc[parsedVariationId]) {
            acc[parsedVariationId] = {};
        }
        acc[parsedVariationId][parsedVariableName] = value;
        return acc;
    }, {});
}
async function watchResolvedVariables({ window: window, }) {
    const resolvedVariables = window[CONST_NAME];
    if (resolvedVariables) {
        return Promise.resolve(parseMappedVariables(resolvedVariables));
    }
    else {
        return Promise.race([
            new Promise(resolve => {
                window.addEventListener(EVENT_NAME, () => {
                    const mappedVariables = window[CONST_NAME];
                    resolve(parseMappedVariables(mappedVariables ?? {}));
                });
            }),
            new Promise(resolve => setTimeout(() => resolve({}), TIMEOUT_MS)),
        ]);
    }
}
export const createServerSideVariablesClient = ({ window, storageKey, }) => {
    const variablesStorage = createVariablesStorage({
        storage: window.localStorage,
        key: storageKey,
    });
    const watchPromise = watchResolvedVariables({ window });
    async function getVariables({ variationId, usePreviousValue, }) {
        if (usePreviousValue) {
            (async () => {
                const result = await watchPromise;
                variablesStorage.set(result);
            })();
            const prevResult = variablesStorage.get();
            return prevResult[variationId] ?? {};
        }
        else {
            const result = await watchPromise;
            variablesStorage.set(result);
            return result[variationId] ?? {};
        }
    }
    return {
        getVariables,
    };
};
