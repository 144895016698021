import type _KeenSlider from 'keen-slider';

type KeenSliderPkgType = typeof _KeenSlider;

export async function asyncLoadKeenSliderPkg({
  version,
}: {
  version: string;
}): Promise<KeenSliderPkgType> {
  const KEEN_SLIDER_CDN_URL = `https://cdn.jsdelivr.net/npm/keen-slider@${version}/+esm`;
  const pkg = await import(/* webpackIgnore: true */ KEEN_SLIDER_CDN_URL);
  return pkg.default;
}
