import type { actionTableClient } from 'rewrite-common';
import { SCRIPT_VARIATION_ID_IDENTIFIER } from '../render/utils';
import { CollectionAPI, createCollectionAPI } from './collection-api';
import { CarouselAPI, CarouselConstructor } from './carousel-api';

export * from './utils';
export * from './carousel-api';

type ActionTableClient = actionTableClient.ActionTableClient;

type BaseEventHandler = (value: any) => void;
type Disposer = () => void;

export interface BlockAPI {
  setVal(name: string, value: any): void;
  getVal(name: string): any;
  on(event: string, handler: (value: any) => void): Disposer;
  show(): void;
  hide(
    options?: Partial<{
      keepArea: boolean;
    }>,
  ): void;
  getBlockId(): string;
  collection(tableName: string): CollectionAPI;
  carousel: CarouselAPI;
}

export const createBlockAPI = ({
  setVariable,
  getVariable,
  onAddEventHandler,
  setVisibility,
  blockId,
  actionTableClient,
  getCarouselConstructor,
}: {
  setVariable(name: string, value: any): void;
  getVariable(name: string): any;
  onAddEventHandler(event: string, handler: BaseEventHandler): Disposer;
  setVisibility(visibility: 'visible' | 'invisible' | 'hidden'): void;
  blockId: string;
  actionTableClient: ActionTableClient;
  getCarouselConstructor: () => CarouselConstructor;
}): BlockAPI => {
  const blockAPI: BlockAPI = {
    setVal(name, value) {
      setVariable(name, value);
    },
    getVal(name) {
      return getVariable(name);
    },
    on(event, handler) {
      const disposer = onAddEventHandler(event, handler);
      return () => {
        disposer();
      };
    },
    show() {
      setVisibility('visible');
    },
    hide(options) {
      if (options?.keepArea) {
        setVisibility('invisible');
      } else {
        setVisibility('hidden');
      }
    },
    getBlockId() {
      return blockId;
    },
    collection(tableName: string) {
      return createCollectionAPI(tableName, actionTableClient);
    },
    carousel: {
      use() {
        return getCarouselConstructor();
      },
    },
  };
  return blockAPI;
};

export const GLOBAL_BLOCK_API_ACCESSOR = '__KARTE_BLOCK_API';

export function addGlobalGetter(window: Window, getter: (variationId: string) => BlockAPI) {
  window[GLOBAL_BLOCK_API_ACCESSOR] = (blockId: string) => {
    return getter(blockId);
  };
}

export function prependBlockAPI(script: string): string {
  return `
const krtBlock = (() => {
try {
  return window.${GLOBAL_BLOCK_API_ACCESSOR}(${SCRIPT_VARIATION_ID_IDENTIFIER});
} catch (e) {
  console.error(e);
}
})();
${script}
`;
}
