import { Directive } from '../dynamic-renderer/types';

export interface ImpressionObserver {
  observe(el: Element, onImpression: () => void): () => void;
  disconnect(): void;
}

function createImpressionObserver(): ImpressionObserver {
  const callbacks = new Map<Element, () => void>();

  const observer = new IntersectionObserver(
    entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const targetEl = entry.target;
          const callback = callbacks.get(targetEl);
          callback?.();
        }
      });
    },
    {
      threshold: 0.5,
    },
  );

  function observe(el: Element, onImpression: () => void) {
    callbacks.set(el, onImpression);
    observer.observe(el);
    return () => {
      observer.unobserve(el);
      callbacks.delete(el);
    };
  }

  function disconnect() {
    observer.disconnect();
    callbacks.clear();
  }

  return {
    observe,
    disconnect,
  };
}

type EventConfig = {
  eventName: string;
  valuesExpr: string;
};

function toEventConfig(raw: any): EventConfig | undefined {
  const { eventName, valuesExpr } = (raw ?? {}) as Record<string, unknown>;
  if (typeof eventName === 'string' && typeof valuesExpr === 'string') {
    return {
      eventName,
      valuesExpr,
    };
  }
}

type TrackingEvent = {
  eventName: string;
  values: Record<string, any>;
};

function toTrackingEvent(
  eventConfig: EventConfig,
  evaluate: Parameters<Directive>[0]['evaluate'],
): TrackingEvent {
  return {
    eventName: eventConfig.eventName,
    values: evaluate(eventConfig.valuesExpr),
  };
}

type ChangeEnabledHandler = () => void;
type Disposer = () => void;
interface SelectorStatesStore {
  getEnabled: (selector: string) => boolean;
  setEnabled: (selector: string, value: boolean) => void;
  onChangeEnabled: (selector: string, handler: ChangeEnabledHandler) => Disposer;
}
interface SelectorState {
  enabled: boolean;
  handlers: Set<ChangeEnabledHandler>;
}
function createSelectorStatesStore(): SelectorStatesStore {
  const selectorStatesMap: Record<string, SelectorState> = {};

  function createSelectorState(): SelectorState {
    return {
      enabled: false,
      handlers: new Set(),
    };
  }

  return {
    getEnabled(selector) {
      return selectorStatesMap[selector]?.enabled ?? false;
    },
    setEnabled(selector, value) {
      const state =
        selectorStatesMap[selector] ?? (selectorStatesMap[selector] = createSelectorState());
      state.enabled = value;
      state.handlers.forEach(handler => handler());
    },
    onChangeEnabled(selector, handler) {
      const state =
        selectorStatesMap[selector] ?? (selectorStatesMap[selector] = createSelectorState());
      state.handlers.add(handler);
      return () => {
        state.handlers.delete(handler);
      };
    },
  };
}

interface Tracker {
  track(eventName: string, values?: any): void;
}
export const createTrackingDirective: ({ tracker }: { tracker: Tracker }) => {
  directive: Directive;
  dispose: () => void;
  setEnabled: (selector: string, enabled: boolean) => void;
} = ({ tracker }) => {
  const impressionObserver = createImpressionObserver();
  const selectorStatesStore = createSelectorStatesStore();

  const directive: Directive = ({ el, arg, effect, expr, evaluate, getRootSelector }) => {
    let _eventConfig: EventConfig | undefined;
    try {
      _eventConfig = toEventConfig(JSON.parse(expr));
    } catch (e) {
      /* noop */
    }
    if (!_eventConfig) return () => {};
    const eventConfig: EventConfig = _eventConfig;

    let eventListenerDisposer: (() => void) | undefined;
    let impressionEventTriggered = false;
    let enabled = false;

    function refreshEventListener() {
      eventListenerDisposer?.();
      if (!enabled) {
        eventListenerDisposer = undefined;
        return;
      }
      if (el instanceof HTMLElement) {
        switch (arg) {
          case 'click': {
            const handler = () => {
              const event = toTrackingEvent(eventConfig, evaluate);
              tracker.track(event.eventName, event.values);
            };
            el.addEventListener('click', handler);
            eventListenerDisposer = () => {
              el.removeEventListener('click', handler);
            };
            break;
          }
          case 'impression': {
            eventListenerDisposer = impressionObserver.observe(el, () => {
              if (!impressionEventTriggered) {
                impressionEventTriggered = true;
                const event = toTrackingEvent(eventConfig, evaluate);
                tracker.track(event.eventName, event.values);
              }
            });
            break;
          }
        }
      }
    }

    effect(() => {
      refreshEventListener();
    });

    const changeEnabledListenerDisposer = selectorStatesStore.onChangeEnabled(
      getRootSelector(),
      () => {
        enabled = selectorStatesStore.getEnabled(getRootSelector());
        refreshEventListener();
      },
    );

    return () => {
      eventListenerDisposer?.();
      changeEnabledListenerDisposer?.();
    };
  };

  const dispose = () => {
    impressionObserver.disconnect();
  };

  const setEnabled = (selector: string, enabled: boolean) => {
    selectorStatesStore.setEnabled(selector, enabled);
  };

  return {
    directive,
    dispose,
    setEnabled,
  };
};
