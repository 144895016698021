/* eslint-disable typescript-compat/compat */
import type * as _PetiteVue from '@plaidev/petite-vue';
import type { ChangeDataHandler, Directive, DynamicRenderer } from './types';
export type PetiteVue = typeof _PetiteVue;

const EXPORT_DIRECTIVE_NAME = '_export';

type PetiteVueApp = ReturnType<PetiteVue['createApp']>;
type PetiteVueDirective = NonNullable<Parameters<PetiteVueApp['directive']>[1]>;
type PetiteVueDirectiveContext = Parameters<PetiteVueDirective>[0];

function unproxify(value: any): any {
  try {
    return JSON.parse(JSON.stringify(value));
  } catch {
    return {};
  }
}

function setAttributesForRender(element: Element, selector: string) {
  element.setAttribute('krt-scope', `Scope('${selector}')`);
  element.setAttribute(`krt-${EXPORT_DIRECTIVE_NAME}`, '{ _getData, _setData, _selector }');
}

/** @internal */
export function createPetiteVueDirective(directive: Directive): PetiteVueDirective {
  return ({ el, get, effect, exp, arg, modifiers }: PetiteVueDirectiveContext) => {
    return directive({
      el,
      arg,
      modifiers: Object.keys(modifiers ?? {}),
      expr: exp,
      effect,
      evaluate: get,
      getRootSelector() {
        return get('_selector');
      },
    });
  };
}

/** @internal */
export function toDisplayString(value: any) {
  return value == null
    ? ''
    : value !== null && typeof value === 'object' // isObject
    ? JSON.stringify(value, null, 2)
    : String(value);
}

// eslint-disable-next-line @takurinton/function-with-test/require-test
export function krtTextCompatibleTemplate(texts: TemplateStringsArray, ...values: any[]) {
  const stringifiedValues = values.map(toDisplayString);
  return texts.reduce((acc, text, i) => acc + text + (stringifiedValues[i] ?? ''), '');
}

function createExportDirective(callback: (value: any) => void): PetiteVueDirective {
  return ({ get, effect, exp }) => {
    effect(() => {
      const value = get(exp);
      callback(value);
    });
  };
}

function Scope(selector: string) {
  return {
    data: {},
    template: krtTextCompatibleTemplate,
    _setData(data: any) {
      this.data = data;
    },
    _getData() {
      return this.data;
    },
    _selector: selector,
  };
}

export function createPetiteVueDynamicRenderer({
  selectors,
  PetiteVuePkg,
  directives,
}: {
  selectors: string[];
  PetiteVuePkg: PetiteVue;
  directives?: Record<string, Directive>;
}): DynamicRenderer {
  const rootElementsMap: Record<string, Element> = {};

  const reactiveScopeValuesMap: Record<string, { _setData: any; _getData: any }> = {};

  const onChangeDataHandlers: Record<string, Set<ChangeDataHandler>> = {};

  let app: PetiteVueApp | undefined;

  const render: DynamicRenderer['render'] = () => {
    const { createApp } = PetiteVuePkg;

    selectors.forEach(selector => {
      const element = document.querySelector(selector);
      if (element) {
        rootElementsMap[selector] = element;
      }
    });

    // 適用対象要素がない場合は中断
    // (petite-vueがページ全体に適用されるのを防ぐ)
    if (Object.values(rootElementsMap).length === 0) return () => {};
    Object.entries(rootElementsMap).forEach(([selector, element]) => {
      setAttributesForRender(element, selector);
    });

    const exportDirective = createExportDirective(value => {
      const selector = value._selector;
      if (selector) {
        reactiveScopeValuesMap[selector] = {
          _getData: value._getData,
          _setData: value._setData,
        };
      }
    });

    app = createApp({
      Scope,
      $delimiters: ['#{', '}'],
    });

    Object.entries(directives ?? {})
      .reduce(
        (app, [name, directive]) => app.directive(name, createPetiteVueDirective(directive)),
        app,
      )
      .directive(EXPORT_DIRECTIVE_NAME, exportDirective)
      .mount();
  };

  const setData: DynamicRenderer['setData'] = (selector, data) => {
    reactiveScopeValuesMap[selector]?._setData(data);
  };

  const getData: DynamicRenderer['getData'] = selector => {
    return unproxify(reactiveScopeValuesMap[selector]?._getData());
  };

  const onChangeData: DynamicRenderer['onChangeData'] = (selector, callback) => {
    if (!onChangeDataHandlers[selector]) {
      onChangeDataHandlers[selector] = new Set();
    }
    onChangeDataHandlers[selector].add(callback);
    return () => {
      onChangeDataHandlers[selector]?.delete(callback);
    };
  };

  const destroy: DynamicRenderer['destroy'] = () => {
    app?.unmount();
    Object.keys(rootElementsMap).forEach(key => {
      delete rootElementsMap[key];
    });
    Object.keys(reactiveScopeValuesMap).forEach(key => {
      delete reactiveScopeValuesMap[key];
    });
    Object.keys(onChangeDataHandlers).forEach(key => {
      delete onChangeDataHandlers[key];
    });
  };

  const { nextTick: _nextTick } = PetiteVuePkg;
  const nextTick: DynamicRenderer['nextTick'] = async fn => {
    return _nextTick(fn ?? (() => {}));
  };

  return {
    render,
    setData,
    getData,
    onChangeData,
    destroy,
    nextTick,
  };
}
