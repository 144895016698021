import type _KeenSlider from 'keen-slider';
import type { KeenSliderInstance } from 'keen-slider';
import type {
  CarouselHookCallback,
  CarouselHooks,
  Carousel,
  CarouselConstructor,
  CarouselOptions,
  CarouselContainer,
} from './types';

type KeenSliderPkgType = typeof _KeenSlider;
type KeenSliderHookCallback = Parameters<KeenSliderInstance['on']>[1];

const CONTAINER_SELECTOR = `.krt-block-carousel`;

const CONTAINER_SELECTOR_NOT_DISABLED = `${CONTAINER_SELECTOR}:not([data-keen-slider-disabled])`;

const SLIDE_SELECTOR = `${CONTAINER_SELECTOR}__slide`;

const style = `
${CONTAINER_SELECTOR_NOT_DISABLED} {
  align-content: flex-start;
  display: flex;
  overflow: hidden;
  position: relative;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
}

${CONTAINER_SELECTOR_NOT_DISABLED} ${SLIDE_SELECTOR} {
  position: relative;
  overflow: hidden;
  width: 100%;
  min-height: 100%;
}

${CONTAINER_SELECTOR_NOT_DISABLED}[data-keen-slider-reverse] {
  flex-direction: row-reverse;
}

${CONTAINER_SELECTOR_NOT_DISABLED}[data-keen-slider-v] {
  flex-wrap: wrap;
}
`;

function appendStyle() {
  const styleDOM = document.createElement('style');
  styleDOM.textContent = style;
  document.head.append(styleDOM);
  return styleDOM;
}

export function createAsyncKeenSliderCarouselConstructor(
  asyncKeenSliderPkg: Promise<KeenSliderPkgType>,
): CarouselConstructor {
  return class AsyncKeenSliderCarouselCarousel implements Carousel {
    static #styleElement: HTMLStyleElement | undefined;

    #keenSliderInstance: KeenSliderInstance | undefined;
    #preHandlers: Partial<
      Record<CarouselHooks, Map<CarouselHookCallback, KeenSliderHookCallback>>
    > = {};

    constructor(container: CarouselContainer, options: CarouselOptions) {
      this.#init(container, options);
    }

    #getKeenSliderInstanceOrWarn(): KeenSliderInstance | undefined {
      if (this.#keenSliderInstance) {
        return this.#keenSliderInstance;
      } else {
        // クライアントも検知できるよう、modules/loggerは使わない
        const logger = console;
        logger.warn('Initialization is not finished.');
      }
    }

    destroy() {
      this.#getKeenSliderInstanceOrWarn()?.destroy();
    }

    next() {
      this.#getKeenSliderInstanceOrWarn()?.next();
    }

    prev() {
      this.#getKeenSliderInstanceOrWarn()?.prev();
    }

    moveTo(index: number) {
      this.#getKeenSliderInstanceOrWarn()?.moveToIdx(index);
    }

    get slides() {
      return this.#getKeenSliderInstanceOrWarn()?.slides ?? [];
    }

    on(name: CarouselHooks, callback: CarouselHookCallback) {
      const keenSliderCallback: KeenSliderHookCallback = () => {
        callback(this);
      };
      if (this.#keenSliderInstance) {
        const instance = this.#keenSliderInstance;
        instance.on(name, keenSliderCallback);
      } else {
        (this.#preHandlers[name] ?? (this.#preHandlers[name] = new Map())).set(
          callback,
          keenSliderCallback,
        );
      }
      return () => {
        if (this.#keenSliderInstance) {
          this.#keenSliderInstance.on(name, keenSliderCallback, true);
        } else {
          this.#preHandlers[name]?.delete(callback);
        }
      };
    }

    async #init(
      container: CarouselContainer,
      {
        loop = false,
        freeMode = 'none',
        slides: { perView: slidesPerView = 1 } = {},
      }: CarouselOptions,
    ) {
      const KeenSlider = await asyncKeenSliderPkg;
      new KeenSlider(container, {
        selector: SLIDE_SELECTOR,
        loop,
        mode: freeMode === 'none' ? 'snap' : freeMode,
        slides: {
          perView: slidesPerView,
        },
        created: instance => {
          this.#keenSliderInstance = instance;
          Array.from(this.#preHandlers['created']?.keys() ?? []).forEach(callback => {
            // 非同期を保証する
            setTimeout(() => {
              callback(this);
            }, 0);
          });
          (
            Object.entries(this.#preHandlers) as [
              CarouselHooks,
              Map<CarouselHookCallback, KeenSliderHookCallback>,
            ][]
          )
            .filter(([name]) => name !== 'created')
            .forEach(([name, callbacks]) => {
              Array.from(callbacks.values()).forEach(wrappedCallback => {
                instance.on(name, wrappedCallback, true);
              });
            });
        },
      });

      if (!AsyncKeenSliderCarouselCarousel.#styleElement) {
        AsyncKeenSliderCarouselCarousel.#styleElement = appendStyle();
      }
    }
  };
}
