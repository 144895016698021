const COLLECTION_API_ENDPOINTS = {
    development: 'https://admin.karte.test/collection',
    evaluation: 'https://action-table.dev-karte.com/collection',
    production: 'https://action-table.karte.io/collection',
};
export function createActionTableClient({ apiKey, env, }) {
    async function request(path, data) {
        const response = await fetch(`${COLLECTION_API_ENDPOINTS[env]}${path}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'text/plain;charset=UTF-8',
            },
            body: JSON.stringify({
                api_key: apiKey,
                ...data,
            }),
            mode: env === 'evaluation' ? 'no-cors' : undefined,
        });
        if (!response.ok)
            throw response;
        return await response.json();
    }
    async function getByKey({ tableName, key }) {
        return await request('/getByKey', {
            name: tableName,
            key,
        });
    }
    async function getByKeys({ tableName, keys }) {
        return await request('/getByKeys', {
            name: tableName,
            keys,
        });
    }
    async function getByQuery({ tableName, queryName, queryParams, queryOptions, }) {
        return await request('/getByQuery', {
            name: tableName,
            query_name: queryName,
            params: queryParams,
            options: queryOptions,
        });
    }
    return {
        getByKey,
        getByKeys,
        getByQuery,
    };
}
