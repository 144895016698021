import { ResolverTypes } from '../types/variablesQueryTypes';
const _SERVER_SIDE_RESOLVERS = {
    [ResolverTypes.UserData]: ResolverTypes.UserData,
};
const _CLIENT_SIDE_RESOLVERS = {
    [ResolverTypes.ActionTableRow]: ResolverTypes.ActionTableRow,
    [ResolverTypes.ActionTableRows]: ResolverTypes.ActionTableRows,
    [ResolverTypes.ActionTableQuery]: ResolverTypes.ActionTableQuery,
};
export const SERVER_SIDE_RESOLVERS = Object.values(_SERVER_SIDE_RESOLVERS);
export const CLIENT_SIDE_RESOLVERS = Object.values(_CLIENT_SIDE_RESOLVERS);
