import './modules/polyfills';
import { BlocksImpressionAnalyzer } from './modules/intesectionObserver';
import { Raw, Builder } from './modules/config_types';
import { exposeDebuggerUtilityToGlobal } from './modules/debugger-utility';
import { logger } from './modules/logger';
import { observeMutation } from './modules/observer';
import { createState, checkCampaignPriority } from './modules/store';
import * as samplingFilter from './modules/sampling_filter';
import * as useragentFilter from './modules/useragent_filter';
import { createStyleSheet, insertVisibilityHiddenStyle, removeStyle } from './modules/style';
import {
  extractMatchedConditionsByPageGroups,
  extractMatchedPageGroups,
  extractMatchedPatterns,
  extractMatchedVariations,
  extractRewriteSrc,
  extractSkippedVariations,
  createVariationsForSkippedEvent,
  _extractMatchedConditionsByPageGroups,
} from './modules/condition_matcher';
import * as controlGroup from './modules/control_group';
import {
  addEventListenersToDoms,
  allocateIdToBlockArea,
  allocateSkippedIdToBlockArea,
  rewrite,
  RewriteOption,
  SKIPPED_AREA_IDS_ATTR,
} from './modules/render';
import { BLOCK_ID_ATTR, createBlockSelector } from './modules/render/utils';
import { find } from './modules/polyfill/find';
import {
  sendApplyEvents,
  sendClickEvent,
  sendImpressionEvent,
  sendPageGroupsViewEvents,
  sendPageViewEvent,
  sendBufferingEvents,
  sendSkippedAreaClickEvent,
  sendSkippedAreaImpressionEvent,
  sendOtherEvent,
  sendDynamicBlockPageViewEvent,
} from './eventSender';
import { syncDateTimeWithServer } from './modules/scheduler';
import { setMatchedBlocksSegments } from './modules/blocksSegment';
import { DynamicBlock } from './modules/dynamic-block';
import { createDynamicBlock } from './modules/dynamic-block';
import { newGa4IntegrationClient } from './modules/ga4';
import { exponentialBackoff } from './modules/exponentialBackoff';
import {
  addGlobalGetter,
  asyncLoadKeenSliderPkg,
  createAsyncKeenSliderCarouselConstructor,
  createBlockAPI,
  prependBlockAPI,
} from './modules/block-api';
import { actionTableClient } from 'rewrite-common';
import type _KeenSlider from 'keen-slider';

type KeenSliderPkg = typeof _KeenSlider;

let KeenSliderPkgPromise: Promise<KeenSliderPkg> | undefined;

const { createActionTableClient } = actionTableClient;

let isPost = false;

// tmp log
// see https://github.com/plaidev/karte-io-systems/issues/106528#issuecomment-2235834998
function inspection(
  matchedConditionsByCampaignPriority: Readonly<Builder.Condition[]>,
  matchedConditionsByPriority: Readonly<Builder.Condition[]>,
) {
  if (window.DD_LOGS) {
    return;
  }

  if (isPost) {
    return;
  }

  (function (h: any, o: any, u: any, n: any, d: any) {
    h = h[d] = h[d] || {
      q: [],
      onReady: function (c: any) {
        h.q.push(c);
      },
    };
    d = o.createElement(u);
    d.async = 1;
    d.src = n;
    n = o.getElementsByTagName(u)[0];
    n?.parentNode?.insertBefore(d, n);
  })(
    window,
    document,
    'script',
    'https://www.datadoghq-browser-agent.com/us5/v5/datadog-logs.js',
    'DD_LOGS',
  );

  window.DD_LOGS?.onReady(function () {
    window.DD_LOGS?.init({
      clientToken: 'pub55fbf40cee3706e3bed425ba5c1d3089',
      applicationId: 'c5915fca-f07e-4d35-bb58-9a6fff73bda6',
      site: 'us5.datadoghq.com',
      service: 'tmp-builderjs-inspection',
      env: 'production',
      defaultPrivacyLevel: 'mask-user-input',
      sampleRate: 100,
      trackInteractions: true,
      forwardErrorsToLogs: false,
      beforeSend: (log: any) => {
        const { matchedConditionsByCampaignPriority, matchedConditionsByPriority } = log;
        if (
          // それぞれの conditions が存在しなかったらログを投げない
          matchedConditionsByCampaignPriority === undefined &&
          matchedConditionsByPriority === undefined
        ) {
          return false;
        }
      },
    });

    const isSameLength =
      matchedConditionsByCampaignPriority.length === matchedConditionsByPriority.length;

    if (!isSameLength) {
      isPost = true;
      window.DD_LOGS?.logger?.error(
        '[tmp]priorityで並び替えたときとcampaignPriorityで並び替えたときのconditionsの長さが一致しません!!(動作には影響ありません)',
        {
          matchedConditionsByCampaignPriority,
          matchedConditionsByPriority,
          matchedConditionsByCampaignPriorityLength: matchedConditionsByCampaignPriority.length,
          matchedConditionsByPriorityLength: matchedConditionsByPriority.length,
        },
      );
    }

    const diffIndexArray = [];
    const isConditionsEqual = (() => {
      for (let i = 0; i < matchedConditionsByCampaignPriority.length; i++) {
        if (
          matchedConditionsByCampaignPriority[i].conditionId !==
          matchedConditionsByPriority[i].conditionId
        ) {
          diffIndexArray.push(i);
          return false;
        }
      }
      return true;
    })();

    if (!isConditionsEqual) {
      isPost = true;
      window.DD_LOGS?.logger?.error(
        '[tmp]priorityで並び替えたときとcampaignPriorityで並び替えたときのconditionsの内容が一致しません!!(動作には影響ありません)',
        {
          // 全部出力
          matchedConditionsByCampaignPriority,
          matchedConditionsByPriority,
          // 部分的に見やすい形で出力
          formattedMatchedConditionsByCampaignPriority: matchedConditionsByCampaignPriority.map(
            c => {
              return {
                conditionId: c.conditionId,
                priority: c.priority,
                campaignPriority: c.campaignPriority,
                pageGroupId: c.pageGroupId,
              };
            },
          ),
          formattedMatchedConditionsByPriority: matchedConditionsByPriority.map(c => {
            return {
              conditionId: c.conditionId,
              priority: c.priority,
              campaignPriority: c.campaignPriority,
              pageGroupId: c.pageGroupId,
            };
          }),
          // 一致しなかったindex
          diffIndexArray,
          ident: '1',
        },
      );
    }
  });
}

const IS_ENABLE_UA_FILTER = false;
const KARTE_BLOCKS_FORCE_ACTIVATE_EVENT = 'karte-blocks-force-activate';

function isDynamicBlockTargetVariation(variation: Builder.Variation): boolean {
  const isTargetType = !(['remove', 'original'] as Builder.VariationTypes[]).includes(
    variation.type,
  );
  const isRendered = document
    .querySelector(createBlockSelector(variation.variationId))
    ?.hasAttribute(ATTR_DYNAMIC_BLOCK_RENDERED);
  return isTargetType && !isRendered;
}

const ATTR_DYNAMIC_BLOCK_RENDERED = 'data-krt-dynamic-block-rendered';

export function rewriteMain(krt_rewrite_config: any, deploymentId: string): void {
  // initialize states
  const config: Raw.Config = krt_rewrite_config as any;
  setMatchedBlocksSegments(config.populatedSegments);
  const state = createState();

  exposeDebuggerUtilityToGlobal();
  // ここで server と browser 間の時間を同期する。初回は同期されない。
  syncDateTimeWithServer({ trackUrl: config.trackUrl });
  logger.info('[config]', config);
  logger.info('[state]', state);

  // not to run rewrite when flagged by extension
  if (state.shouldSkipBuilderJs) return;

  if (samplingFilter.isBlocked(state.rewriteUid, config.sampling)) return;
  logger.info('[sampling filter] passed');
  if (IS_ENABLE_UA_FILTER && !useragentFilter.isSupportedUserAgent(navigator.userAgent)) return;
  logger.info('[useragent filter] passed');

  const params: BuilderContent = {
    config,
    styleSheet: createStyleSheet(),
    deploymentId,
  };
  // URLが変わるごとにbuilderを実行し直す
  const handlers = {
    onUrlChanged() {
      runBuilder(params, handlers);
    },
  };
  runBuilder(params, handlers);
}
export interface BuilderContent {
  config: Raw.Config;
  styleSheet: CSSStyleSheet;
  deploymentId: string;
}

export function runBuilder(
  builderParams: BuilderContent,
  handlers: {
    onUrlChanged: () => void;
  },
) {
  const { config, styleSheet, deploymentId } = builderParams;
  setMatchedBlocksSegments(config.populatedSegments);
  const state = createState();
  const isInControlGroupForAll = controlGroup.isInControlGroupForAll(
    config.hasControlForAll,
    state.rewriteUid,
  );
  logger.info('[hasControlForAll]', config.hasControlForAll);
  logger.info('[isInControlGroupForAll]', isInControlGroupForAll);

  const blockSkippedAreaImpressionAnalyzer = new BlocksImpressionAnalyzer(
    isInControlGroupForAll,
    config,
    deploymentId,
    state,
    (element: HTMLElement, variations: Builder.Variation[]) => {
      const idsAttr = element.getAttribute(SKIPPED_AREA_IDS_ATTR);
      if (!idsAttr) return [];
      const ids = idsAttr.split(',');

      const skippedVariations = [] as Builder.Variation[];
      for (const id of ids) {
        const variation = find(variations, v => v.areaId === id);
        if (variation) {
          skippedVariations.push(variation);
        }
      }
      return skippedVariations;
    },
  );
  const blockImpressionAnalyzer = new BlocksImpressionAnalyzer(
    isInControlGroupForAll,
    config,
    deploymentId,
    state,
    (element: HTMLElement, variations: Builder.Variation[]) => {
      const variationId = element.getAttribute(BLOCK_ID_ATTR);
      if (!variationId) return [];

      const variation = find(variations, v => v.variationId === variationId);
      if (!variation) return [];

      return [variation];
    },
  );

  // create observer
  // eslint-disable-next-line @typescript-eslint/no-empty-function -- 何もしないための関数
  let onDomChanged = () => {}; // rewrite target が見つかるまで何も実行しないようにする
  // eslint-disable-next-line @typescript-eslint/no-empty-function -- 何もしないための関数
  let onUrlChanged = () => {};
  observeMutation(document.documentElement || document.body, {
    onUrlChanged(disconnectObserver) {
      logger.info('on url changed');
      disconnectObserver(); // observer を clear
      if (blockImpressionAnalyzer) blockImpressionAnalyzer.disconnect();
      if (blockSkippedAreaImpressionAnalyzer) blockSkippedAreaImpressionAnalyzer.disconnect();
      onUrlChanged();
      handlers.onUrlChanged();
    },
    onDomChanged(disconnect, lazyReconnect) {
      disconnect();
      onDomChanged();
      lazyReconnect();
    },
  });

  const isCampaignPriorityEnabled = checkCampaignPriority();
  if (isCampaignPriorityEnabled) {
    logger.info('campaign priority mode enabled');
  }

  // 最初にkrt_rewrite_configからPageGroupとConditionがマッチしたものだけを抽出しておく
  const matchedPageGroups = extractMatchedPageGroups(config.pageGroups);
  if (!matchedPageGroups) return;

  // PageGroupにmatchしている場合は、一度だけpageViewイベントを送信
  sendPageViewEvent({ config, state, deploymentId });
  // matchしているPageGroupの分だけviewイベント送信
  sendPageGroupsViewEvents({
    pageGroups: matchedPageGroups,
    isInControlGroupForAll,
    config,
    state,
    deploymentId,
  });

  logger.info('[matched page groups]', matchedPageGroups);

  const matchedConditions = _extractMatchedConditionsByPageGroups({
    pageGroups: matchedPageGroups,
    state,
    isInControlGroupForAll,
  });

  const matchedConditionsByPriority = extractMatchedConditionsByPageGroups({
    pageGroups: matchedPageGroups,
    state: state,
    isInControlGroupForAll: isInControlGroupForAll,
  });

  if (matchedConditions.length === 0) {
    return;
  }

  sendApplyEvents({
    pageGroups: matchedPageGroups,
    isInControlGroupForAll,
    config,
    state,
    deploymentId,
  });

  // edge.jsが入っているサイトで取得できるkrtの関数が呼べずイベントが送れないときに、eventBufferにイベントを退避させている
  // sendBufferingEventsの内部でeventBufferが空の時には送らない制御が入っているので常にeventBufferを呼んでも大丈夫な実装になっている
  exponentialBackoff(
    _ => sendBufferingEvents(config.apiKey),
    (result: boolean) => result,
    20,
    100,
    3000,
  );

  const matchedPatterns = extractMatchedPatterns(matchedConditions);
  if (!matchedPatterns) return;
  logger.info('[matched patterns]', matchedPatterns);

  const matchedVariations = extractMatchedVariations(matchedPatterns);
  if (!matchedVariations) return;
  logger.info('[matched variations]', matchedVariations);

  const styleSrc = extractRewriteSrc(matchedVariations);
  if (!styleSrc) return;

  const ga4IntegrationClient = newGa4IntegrationClient(config);
  for (const matchedPattern of matchedPatterns) {
    ga4IntegrationClient.sendDistributedPatternData(matchedPattern);
  }

  if (config.isEnabledDynamicBlock) {
    sendDynamicBlockPageViewEvent({
      config,
      state,
      deploymentId,
      variations: matchedVariations,
    });
  }

  // rewrite

  // はじめに透明化
  insertVisibilityHiddenStyle(styleSheet, styleSrc);

  const rewriteDoms = (options?: RewriteOption) => {
    logger.info('rewrite doms');

    // 先にidを付与
    matchedVariations.forEach(variation => {
      allocateIdToBlockArea(variation);
    });

    // 先に skipped area id を付与
    allocateSkippedIdToBlockArea({
      matchedPageGroups: matchedPageGroups as Raw.PageGroup[], // なぜか推論してくれないので明示
      matchedVariations,
    });

    const actionTableClientEnv: actionTableClient.Env = (() => {
      switch (process.env.NODE_ENV) {
        case 'development':
          return 'development';
        case 'evaluation':
          return 'evaluation';
        default:
          return 'production';
      }
    })();
    const actionTableClient = createActionTableClient({
      apiKey: config.apiKey,
      env: actionTableClientEnv,
    });
    let dynamicBlock: DynamicBlock | undefined;
    if (config.isEnabledDynamicBlock) {
      dynamicBlock = createDynamicBlock({
        apiKey: config.apiKey,
        usePreviewValue: process.env.NODE_ENV === 'development',
        tracker: {
          track(eventName, values) {
            sendOtherEvent({ config, eventName, values });
          },
        },
        actionTableClient,
      });
      addGlobalGetter(window, (variationId: string) => {
        const blockAPI = createBlockAPI({
          setVariable(name, value) {
            dynamicBlock?.setVariable(variationId, name, value);
          },
          getVariable(name) {
            return dynamicBlock?.getVariable(variationId, name);
          },
          setVisibility(visibility) {
            dynamicBlock?.setVisibility(variationId, visibility);
          },
          onAddEventHandler(event, handler) {
            const removeListener = dynamicBlock?.on(variationId, event, value => {
              handler(value);
            });
            return () => {
              removeListener?.();
            };
          },
          blockId: variationId,
          actionTableClient,
          getCarouselConstructor() {
            const asyncPkg =
              KeenSliderPkgPromise ??
              (KeenSliderPkgPromise = asyncLoadKeenSliderPkg({
                version: process.env['KEEN_SLIDER_VERSION'] ?? 'latest',
              }));
            return createAsyncKeenSliderCarouselConstructor(asyncPkg);
          },
        });
        return blockAPI;
      });
    }

    matchedVariations.forEach(variation => {
      if (config.isEnabledDynamicBlock) {
        rewrite(
          {
            ...variation,
            script: isDynamicBlockTargetVariation(variation)
              ? prependBlockAPI(variation.script ?? '')
              : variation.script,
          },
          options,
        );
      } else {
        rewrite(variation, options);
      }
    });

    if (dynamicBlock) {
      const dynamicBlockRenderTargets = matchedVariations
        .filter(variation => isDynamicBlockTargetVariation(variation))
        .map(variation => ({
          variation,
          selector: createBlockSelector(variation.variationId),
        }));
      dynamicBlockRenderTargets.forEach(({ selector }) => {
        document.querySelector(selector)?.setAttribute(ATTR_DYNAMIC_BLOCK_RENDERED, '');
      });
      dynamicBlock.render(dynamicBlockRenderTargets);
    }

    const skippedVariations = extractSkippedVariations({
      pageGroups: matchedPageGroups as Raw.PageGroup[], // なぜか推論してくれないので明示
      state,
    });
    const variationsForSkippedEvent = createVariationsForSkippedEvent(skippedVariations);
    variationsForSkippedEvent.forEach(variation => {
      addEventListenersToDoms({
        elementId: variation.areaId,
        cssSelector: `[${SKIPPED_AREA_IDS_ATTR}*="${variation.areaId}"]`,
        onClick() {
          const conditions = [{ ...variation }];
          sendSkippedAreaImpressionEvent({
            config,
            state,
            isControlForAll: isInControlGroupForAll,
            conditions,
            pageGroupId: variation.pageGroupId,
            deploymentId,
          });
          sendSkippedAreaClickEvent({
            config,
            state,
            isControlForAll: isInControlGroupForAll,
            conditions,
            pageGroupId: variation.pageGroupId,
            deploymentId,
          });
        },
      });
    });

    blockSkippedAreaImpressionAnalyzer.observeVariation(
      variationsForSkippedEvent,
      sendSkippedAreaImpressionEvent,
    );

    matchedVariations.forEach(variation => {
      addEventListenersToDoms({
        elementId: variation.variationId,
        cssSelector: createBlockSelector(variation.variationId),
        onClick() {
          const conditions = [{ ...variation }];
          /**
           * clickされたら、impressionしたとみなして、impressionイベントを送り
           * その後clickイベントを送信する
           *
           * ブロックが大きいときに、intersection observerのしきい値 (画面に何%表示されたか) を超えず、
           * impressionが計測されないことがあるが、clickしたということは、エンドユーザはこのブロックを見たということなので、
           * clickイベントと同時にimpressionイベントも送っておく
           */
          sendImpressionEvent({
            config,
            state,
            isControlForAll: isInControlGroupForAll,
            conditions,
            pageGroupId: variation.pageGroupId,
            deploymentId,
          });
          sendClickEvent({
            config,
            state,
            isControlForAll: isInControlGroupForAll,
            conditions,
            pageGroupId: variation.pageGroupId,
            deploymentId,
          });
        },
      });
    });

    blockImpressionAnalyzer.observeVariation(
      matchedVariations as Builder.Variation[],
      sendImpressionEvent,
    );

    removeStyle(styleSheet);

    inspection(matchedConditions, matchedConditionsByPriority);
  };

  // load されたら書き換え
  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', () => {
      // まず最初に書き換えてから、DOM変更を検知して書き換えの処理(onDomChanged)を上書きする
      // https://github.com/plaidev/karte-io-systems/issues/31581
      rewriteDoms();
      onDomChanged = rewriteDoms;
    });
  } else {
    // `DOMContentLoaded` already fired
    logger.info('DOMContentLoaded already fired');
    // まず最初に書き換えてから、DOM変更を検知して書き換えの処理(onDomChanged)を上書きする
    // https://github.com/plaidev/karte-io-systems/issues/31581
    rewriteDoms();
    onDomChanged = rewriteDoms;
  }

  // karte-blocks-force-activate event を listen して force rewrite する
  const onKarteBlocksForceActivateEventListening = () => {
    rewriteDoms({ force: true });
  };
  document.addEventListener(
    KARTE_BLOCKS_FORCE_ACTIVATE_EVENT,
    onKarteBlocksForceActivateEventListening,
  );
  onUrlChanged = () => {
    document.removeEventListener(
      KARTE_BLOCKS_FORCE_ACTIVATE_EVENT,
      onKarteBlocksForceActivateEventListening,
    );
  };
}
