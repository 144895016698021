import { SERVER_SIDE_RESOLVERS } from '../constants';
export { createServerSideVariablesClient } from './client';
function isEmptyValue(value) {
    return value === undefined || value === null || value === '';
}
function isServerSideVariableQuery(variableQuery) {
    return SERVER_SIDE_RESOLVERS.some(serverSideResolver => variableQuery.resolver === serverSideResolver);
}
export function createServerSideVariablesQueryResolver(context, variablesQuery) {
    const VALUE_TO_AVOID_RUNTIME_ERRORS = '';
    const variablesClient = context.serverSideVariablesClient;
    async function resolve() {
        const serverSideResult = await variablesClient.getVariables({
            variationId: context.variationId,
            usePreviousValue: context.usePreviousValue,
        });
        return Object.fromEntries(variablesQuery.filter(isServerSideVariableQuery).map(vq => {
            const value = serverSideResult[vq.name];
            return [vq.name, isEmptyValue(value) ? vq.query.default_value : value];
        }));
    }
    async function resolvePreview() {
        return Object.fromEntries(variablesQuery.filter(isServerSideVariableQuery).map(vq => {
            return [vq.name, vq.preview_value ?? VALUE_TO_AVOID_RUNTIME_ERRORS];
        }));
    }
    function getInitial() {
        return Object.fromEntries(variablesQuery.filter(isServerSideVariableQuery).map(vq => {
            return [vq.name, vq.query.placeholder_value ?? VALUE_TO_AVOID_RUNTIME_ERRORS];
        }));
    }
    return {
        resolve,
        resolvePreview,
        getInitial,
    };
}
