import { Directive } from '../dynamic-renderer/types';

const STYLE_ID = 'krt-dynamic-block-visibility-directive-styles';

const STYLE_CLASSES: Record<string, string> = {
  visible: 'krt-dynamic-block-visibility-directive--visible',
  invisible: 'krt-dynamic-block-visibility-directive--invisible',
  hidden: 'krt-dynamic-block-visibility-directive--hidden',
};

export const visibilityDirective: Directive = ({ el, expr, effect, evaluate }) => {
  function init() {
    if (document.getElementById(STYLE_ID)) return;
    const style = document.createElement('style');
    style.setAttribute('id', STYLE_ID);
    document.head.appendChild(style);
    const styleSheet = style.sheet;
    if (!styleSheet) return;
    styleSheet.insertRule(`
.${STYLE_CLASSES.invisible} {
  visibility: hidden;
}
`);
    styleSheet.insertRule(`
.${STYLE_CLASSES.hidden} {
  display: none;
}
`);
  }

  function clear() {
    if (el instanceof HTMLElement) {
      el.classList.remove(...Object.values(STYLE_CLASSES));
    }
  }
  effect(() => {
    const visibility = evaluate(expr);
    if (el instanceof HTMLElement) {
      el.classList.remove(...Object.values(STYLE_CLASSES));
      STYLE_CLASSES[visibility] && el.classList.add(STYLE_CLASSES[visibility]);
    }
  });

  init();

  return () => {
    if (el instanceof HTMLElement) {
      clear();
    }
  };
};
