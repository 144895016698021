import {
  Raw,
  variablesQueryResolver,
  variablesQueryTypes,
  actionTableClient,
} from 'rewrite-common';
import { createPetiteVueDynamicRenderer } from './dynamic-renderer';
import * as PetiteVue from '@plaidev/petite-vue';
import { visibilityDirective } from './directives/visibility';
import { createTrackingDirective } from './directives/tracking';
import { DynamicRenderer } from './dynamic-renderer/types';

type VariableQuery = variablesQueryTypes.VariableQuery;
type ResolverQueryDefinition = variablesQueryTypes.ResolverQueryDefinition;
type VariablesQueryResolver = variablesQueryResolver.VariablesQueryResolver;
type ResolvedVariables = variablesQueryResolver.ResolvedVariables;
type ActionTableClient = actionTableClient.ActionTableClient;

const {
  createServerSideVariablesQueryResolver,
  createClientSideVariablesQueryResolver,
  createStaticVariablesQueryResolver,
  createServerSideVariablesClient,
  SERVER_SIDE_RESOLVERS,
  CLIENT_SIDE_RESOLVERS,
} = variablesQueryResolver;

const LOCAL_KEYS = {
  KRT_REWRITE_SERVER_SIDE_RESOLVED_VARIABLES: 'krt_rewrite_server_side_resolved_variables',
};

const VISIBILITY_DIRECTIVE = 'visibility';
const TRACKING_DIRECTIVE = 'tracking';

export type DynamicBlockEvent = string;

export type RenderTarget = { variation: Raw.Variation; selector: string };
type RenderState = {
  dynamicRenderer: DynamicRenderer;
  targets: RenderTarget[];
};
export type DynamicBlock = {
  render(targets: RenderTarget[]): void;
  on(variationId: string, event: DynamicBlockEvent, handler: () => void): () => void;
  setVariable(variationId: string, name: string, value: any): void;
  setVisibility(variationId: string, visibility: 'visible' | 'invisible' | 'hidden'): void;
  getVariable(variationId: string, name: string): any;
  destroy(): void;
};

type Updater<T> = (prev: T) => T;

const combineUpdaters: <T>(f1: Updater<T>, f2: Updater<T>) => Updater<T> = (f1, f2) => {
  return prev => f1(f2(prev));
};

const visibilityUpdater: Updater<Record<string, any>> = prev => {
  const visibilityByLoadingStatus = (() => {
    switch (prev._loadingStatus) {
      case 'loading':
        return 'invisible';
      case 'success':
        return 'visible';
      case 'empty':
        return 'hidden';
      case 'error':
        return 'hidden';
    }
  })();
  return {
    ...prev,
    _visibility: prev._forcedVisibility || visibilityByLoadingStatus,
  };
};

const createDataUpdater: (
  updater: Updater<Record<string, any>>,
) => Updater<Record<string, any>> = updater => {
  return combineUpdaters(visibilityUpdater, updater);
};

function createSetDataByUpdater(
  dynamicRenderer: DynamicRenderer,
  selector: string,
): (updater: Updater<Record<string, any>>) => void {
  return updater => {
    dynamicRenderer.setData(selector, updater(dynamicRenderer.getData(selector)));
  };
}

const serverSideVariablesClient = createServerSideVariablesClient({
  window,
  storageKey: LOCAL_KEYS.KRT_REWRITE_SERVER_SIDE_RESOLVED_VARIABLES,
});

function emptyVariablesExist(
  variables: ResolvedVariables,
  variablesQuery: ResolverQueryDefinition[],
): boolean {
  return variablesQuery
    .map(vq => variables[vq.name])
    .some(value => value === undefined || value === null);
}

export function createDynamicBlock({
  apiKey,
  usePreviewValue = false,
  tracker,
  actionTableClient,
}: {
  apiKey: string;
  usePreviewValue?: boolean;
  tracker?: {
    track(eventName: string, values?: any): void;
  };
  actionTableClient: ActionTableClient;
}): DynamicBlock {
  const handlers: Record<string, Record<DynamicBlockEvent, Set<() => void>>> = {};

  const preloadedData: Record<string, Record<string, any>> = {};

  const trackingDirective = createTrackingDirective({
    tracker: {
      track(eventName, values) {
        tracker?.track(eventName, values);
      },
    },
  });

  let renderState: RenderState | undefined;

  function dispatchEvent(variationId: string, eventName: string) {
    Array.from(handlers[variationId]?.[eventName] ?? []).map(handler => handler());
  }

  function setVariable(variationId: string, name: string, value: any) {
    const dynamicRenderer = renderState?.dynamicRenderer;
    const selector = renderState?.targets.find(
      target => target.variation.variationId === variationId,
    )?.selector;
    if (dynamicRenderer && selector) {
      const setDataByUpdater = createSetDataByUpdater(dynamicRenderer, selector);
      setDataByUpdater(
        createDataUpdater(prev => ({
          ...prev,
          [name]: value,
        })),
      );
    } else {
      preloadedData[variationId] = {
        ...(preloadedData[variationId] ?? {}),
        [name]: value,
      };
    }
  }

  return {
    render(targets) {
      if (renderState) return;
      const selectors = targets.map(({ selector }) => selector);
      const dynamicRenderer = createPetiteVueDynamicRenderer({
        selectors,
        directives: {
          [VISIBILITY_DIRECTIVE]: visibilityDirective,
          [TRACKING_DIRECTIVE]: trackingDirective.directive,
        },
        PetiteVuePkg: PetiteVue,
      });

      renderState = {
        dynamicRenderer,
        targets,
      };

      selectors.forEach(selector => {
        document
          .querySelector(selector)
          ?.setAttribute(`krt-${VISIBILITY_DIRECTIVE}`, 'data._visibility');
      });
      dynamicRenderer.render();

      targets.forEach(async ({ variation, selector }) => {
        const setDataByUpdater = createSetDataByUpdater(dynamicRenderer, selector);
        setDataByUpdater(() => preloadedData[variation.variationId]);

        dispatchEvent(variation.variationId, 'beforeDataLoad');
        const variablesQuery = variation.variablesQuery;

        const serverSideVariablesQuery: VariableQuery[] = [];
        const clientSideVariablesQuery: VariableQuery[] = [];
        const staticVariablesQuery: VariableQuery[] = [];
        (variablesQuery ?? []).forEach(vq => {
          if (SERVER_SIDE_RESOLVERS.some(r => r === vq.resolver)) {
            serverSideVariablesQuery.push(vq as VariableQuery);
          } else if (CLIENT_SIDE_RESOLVERS.some(r => r === vq.resolver)) {
            clientSideVariablesQuery.push(vq as VariableQuery);
          } else {
            staticVariablesQuery.push(vq as VariableQuery);
          }
        });

        let serverSideResolver: VariablesQueryResolver | undefined;
        if (serverSideVariablesQuery.length > 0) {
          serverSideResolver = createServerSideVariablesQueryResolver(
            {
              apiKey,
              variationId: variation.variationId,
              usePreviousValue: !variation.waitLatestUserData,
              serverSideVariablesClient,
            },
            serverSideVariablesQuery,
          );
        }
        let clientSideResolver: VariablesQueryResolver | undefined;
        if (clientSideVariablesQuery.length > 0) {
          clientSideResolver = createClientSideVariablesQueryResolver(
            { apiKey, actionTableClient },
            clientSideVariablesQuery,
          );
        }

        let staticResolver: VariablesQueryResolver | undefined;
        if (staticVariablesQuery.length > 0) {
          staticResolver = createStaticVariablesQueryResolver({ apiKey }, staticVariablesQuery);
        }

        setDataByUpdater(
          createDataUpdater(prev => ({
            ...prev,
            ...serverSideResolver?.getInitial(),
            ...clientSideResolver?.getInitial(),
            ...staticResolver?.getInitial(),
            _loadingStatus: 'loading',
          })),
        );

        try {
          if (serverSideResolver) {
            const variablesResolvedInServer = await (usePreviewValue
              ? serverSideResolver.resolvePreview()
              : serverSideResolver.resolve({}));
            if (emptyVariablesExist(variablesResolvedInServer, serverSideVariablesQuery)) {
              setDataByUpdater(
                createDataUpdater(prev => ({
                  ...prev,
                  _loadingStatus: 'empty',
                })),
              );
              return;
            }
            setDataByUpdater(
              createDataUpdater(prev => ({
                ...prev,
                ...variablesResolvedInServer,
              })),
            );
          }
          if (clientSideResolver) {
            const variablesResolvedInClient = await (usePreviewValue
              ? clientSideResolver.resolvePreview()
              : clientSideResolver.resolve(dynamicRenderer.getData(selector)));
            setDataByUpdater(
              createDataUpdater(prev => ({
                ...prev,
                ...variablesResolvedInClient,
              })),
            );
            if (emptyVariablesExist(variablesResolvedInClient, clientSideVariablesQuery)) {
              setDataByUpdater(
                createDataUpdater(prev => ({
                  ...prev,
                  _loadingStatus: 'empty',
                })),
              );
              return;
            }
          }
          await dynamicRenderer.nextTick();
          setDataByUpdater(
            createDataUpdater(prev => ({
              ...prev,
              _loadingStatus: 'success',
            })),
          );
          trackingDirective.setEnabled(selector, true);
          dispatchEvent(variation.variationId, 'dataLoaded');
        } catch (e) {
          setDataByUpdater(
            createDataUpdater(prev => ({
              ...prev,
              _loadingStatus: 'error',
            })),
          );
        }
      });
    },
    on(variationId, eventName, handler) {
      if (!handlers[variationId]) {
        handlers[variationId] = {};
      }
      if (!handlers[variationId][eventName]) {
        handlers[variationId][eventName] = new Set();
      }
      handlers[variationId][eventName].add(handler);
      return () => {
        handlers[variationId][eventName].delete(handler);
      };
    },
    setVariable(variationId, name, value) {
      setVariable(variationId, name, value);
    },
    getVariable(variationId, name) {
      const dynamicRenderer = renderState?.dynamicRenderer;
      const selector = renderState?.targets.find(
        target => target.variation.variationId === variationId,
      )?.selector;
      if (dynamicRenderer && selector) {
        return (dynamicRenderer.getData(selector) ?? {})[name];
      } else {
        return (preloadedData[variationId] ?? {})[name];
      }
    },
    setVisibility(variationId, visibility) {
      setVariable(variationId, '_forcedVisibility', visibility);
    },
    destroy() {
      renderState?.dynamicRenderer.destroy();
      Object.keys(handlers).forEach(key => {
        delete handlers[key];
      });
      Object.keys(preloadedData).forEach(key => {
        delete preloadedData[key];
      });
      trackingDirective.dispose();
    },
  };
}
