import type { EventName, Event } from './modules/sender';
import { Raw } from './modules/config_types';
import * as senderModule from './modules/sender';
import { BlocksDimension, State } from './modules/store';
import {
  extractMatchedAndFormattedConditions,
  extractMatchedPattern,
} from './modules/condition_matcher';
import { dynamicBlockParser$blockAPIVariable } from 'rewrite-common';

const { extractBlockAPIVariable } = dynamicBlockParser$blockAPIVariable;

type SendPageViewEventArg = {
  config: Raw.Config;
  state: State;
  deploymentId: string;
};

type SendDynamicBlockPageViewEventArg = {
  config: Raw.Config;
  state: State;
  deploymentId: string;
  variations: readonly Raw.Variation[];
};

type BaseEventValueForBlitz = {
  blocks_uuid: string;
  blocks_deployment_id: string;
  on_triggered_blocks_dimensions?: BlocksDimension;
  on_triggered_blocks_segments?: string;
  on_triggered_segments?: string;
  url?: string;
  user_agent?: string;
};

type EventValueForBlitz = BaseEventValueForBlitz & {
  condition_id?: string;
  pattern_id?: string;
  area_id?: string;
  variation_id?: string;
  group_page_id?: string;
  is_control_for_all?: boolean;
  has_control_for_all?: boolean;
};

type PageViewEventValueForBlitz = BaseEventValueForBlitz;

type DynamicBlockPageViewEventValueForBlitz = BaseEventValueForBlitz & {
  variation_ids: string[];
};

type KarteEventValue = Record<string, any>;

type EventBuffer = {
  eventName: string;
  value: KarteEventValue;
};

let eventBuffer: EventBuffer[] = [];

function sendPageViewEvent({ config, state, deploymentId }: SendPageViewEventArg): void {
  callBlitzPageViewEventSender(config.apiKey, state, deploymentId);
}

function isDynamicBlockPVTargetVariation(variation: Raw.Variation): boolean {
  const blockAPIVariables = extractBlockAPIVariable(variation.script ?? '');
  return (variation.variablesQuery?.length ?? 0) > 0 || blockAPIVariables.length > 0;
}

function sendDynamicBlockPageViewEvent({
  config,
  state,
  deploymentId,
  variations,
}: SendDynamicBlockPageViewEventArg): void {
  // パース系の処理がレンダリングをブロックしないよう、念の為非同期にする
  setTimeout(() => {
    const targetVariations = variations.filter(variation =>
      isDynamicBlockPVTargetVariation(variation),
    );
    if (targetVariations.length > 0) {
      callBlitzDynamicBlockPageViewEventSender(
        config.apiKey,
        state,
        deploymentId,
        targetVariations.map(v => v.variationId),
      );
    }
  }, 0);
}

type SendEventArg = {
  config: Raw.Config;
  state: State;
  isControlForAll: boolean;
  conditions: ConditionArg[];
  pageGroupId: string;
  deploymentId: string;
};

type ConditionArg = {
  conditionId: string;
  isControlForCondition: boolean;
  hasControl: boolean;
  patternId?: string;
  areaId?: string;
  variationId?: string;
};

function sendViewEvent(args: SendEventArg): void {
  const { config } = args;
  const events = createEvent('view', args.conditions);
  callBlitzEventSender(config.apiKey, 'view', events, args);
}

function sendApplyEvent(args: SendEventArg): void {
  const { config } = args;
  const events = createEvent('apply', args.conditions);
  callBlitzEventSender(config.apiKey, 'apply', events, args);
}

function sendClickEvent(args: SendEventArg): void {
  const { config } = args;
  const events = createEvent('click', args.conditions);
  callBlitzEventSender(config.apiKey, 'click', events, args);
}

function sendImpressionEvent(args: SendEventArg): void {
  const { config } = args;
  const events = createEvent('impression', args.conditions);
  callBlitzEventSender(config.apiKey, 'impression', events, args);
}

function sendSkippedAreaClickEvent(args: SendEventArg): void {
  const { config } = args;
  const events = createEvent('skippedAreaClick', args.conditions);
  callBlitzEventSender(config.apiKey, 'skippedAreaClick', events, args);
}

function sendSkippedAreaImpressionEvent(args: SendEventArg): void {
  const { config } = args;
  const events = createEvent('skippedAreaImpression', args.conditions);
  callBlitzEventSender(config.apiKey, 'skippedAreaImpression', events, args);
}

// Edge タグが入っている場合にはグローバルな関数として使える
type KRT = (method: string, eventName: string, log: KarteEventValue) => void;

type Track = (eventName: string, log: KarteEventValue) => void;

type Tracker = {
  track: Track;
};

// tracker タグが入っている場合にはグローバルな関数として使える
declare const tracker: Tracker;

/**
 * blitzに送るイベントのうち、pageView以外のイベントのJSONのpropertyを変換する関数
 */
function blitzEventValueConverter(
  keys: {
    pageGroupId?: string;
    uuId: string;
    deploymentId: string;
    isControlForAll: boolean;
    hasControlForAll: boolean;
  },
  events: Event[],
  state: State,
): EventValueForBlitz[] {
  const values: EventValueForBlitz[] = [];
  try {
    for (const event of events) {
      const value = {
        condition_id: event.conditionId,
        pattern_id: event.patternId,
        area_id: event.areaId,
        variation_id: event.variationId,
        group_page_id: keys.pageGroupId,
        blocks_uuid: keys.uuId,
        blocks_deployment_id: keys.deploymentId,
        is_control_for_all: keys.isControlForAll,
        has_control_for_all: keys.hasControlForAll,
        url: window.location.href,
        user_agent: window.navigator.userAgent,
      } as EventValueForBlitz;
      // values が undefined のキーはJSONとして無効なので入らないようにする
      if (state.segments) {
        value.on_triggered_segments = state.segments.join(',');
      }
      if (state.blocksDimensions) {
        value.on_triggered_blocks_dimensions = state.blocksDimensions;
      }
      if (state.blocksSegments) {
        value.on_triggered_blocks_segments = state.blocksSegments;
      }

      values.push(value);
    }
  } catch (err) {
    window.console.error(err);
  }

  return values;
}

/**
 * pageViewだけ引数の方が違うので関数を別に定義する
 */
function blitzPageViewEventValueConverter(
  keys: {
    uuId: string;
    deploymentId: string;
  },
  state: State,
): PageViewEventValueForBlitz[] {
  const values: PageViewEventValueForBlitz[] = [];
  try {
    const value = {
      blocks_uuid: keys.uuId,
      blocks_deployment_id: keys.deploymentId,
      url: window.location.href,
      user_agent: window.navigator.userAgent,
    } as PageViewEventValueForBlitz;
    // values が undefined のキーはJSONとして無効なので入らないようにする
    if (state.segments) {
      value.on_triggered_segments = state.segments.join(',');
    }
    if (state.blocksDimensions) {
      value.on_triggered_blocks_dimensions = state.blocksDimensions;
    }
    if (state.blocksSegments) {
      value.on_triggered_blocks_segments = state.blocksSegments;
    }

    values.push(value);
  } catch (e) {
    window.console.error(e);
  }

  return values;
}

/**
 * ダイナミックブロックPV数計測用イベントのvaluesを生成する
 */
function blitzDynamicBlockPageViewEventValueConverter(
  keys: {
    uuId: string;
    deploymentId: string;
  },
  state: State,
  variationIds: string[],
): DynamicBlockPageViewEventValueForBlitz[] {
  const values: DynamicBlockPageViewEventValueForBlitz[] = [];
  try {
    const value = {
      blocks_uuid: keys.uuId,
      blocks_deployment_id: keys.deploymentId,
      url: window.location.href,
      user_agent: window.navigator.userAgent,
      variation_ids: variationIds,
    } as DynamicBlockPageViewEventValueForBlitz;
    // values が undefined のキーはJSONとして無効なので入らないようにする
    if (state.segments) {
      value.on_triggered_segments = state.segments.join(',');
    }
    if (state.blocksDimensions) {
      value.on_triggered_blocks_dimensions = state.blocksDimensions;
    }
    if (state.blocksSegments) {
      value.on_triggered_blocks_segments = state.blocksSegments;
    }

    values.push(value);
  } catch (e) {
    window.console.error(e);
  }

  return values;
}

/**
 * Blitzにイベントを送る関数
 * 2022年10月ごろの改修でそれまでBlocks独自で収集・解析していたイベントをpockyeventに保存するためにBlitzにイベントを送るようにする
 */
function callBlitzEventSender(
  apiKey: string,
  eventName: EventName,
  events: Event[],
  { config, state, isControlForAll, pageGroupId, deploymentId }: SendEventArg,
): void {
  const eventNameForBlitz = senderModule.BLITZ_BLOCKS_EVENT_NAME_MAP[eventName];
  // pageView のときには callBlitzPageViewEventSender を呼ぶのでreturn
  if (
    !eventNameForBlitz ||
    eventNameForBlitz === senderModule.BLITZ_BLOCKS_EVENT_NAME_MAP.pageView
  ) {
    return;
  }
  const values = blitzEventValueConverter(
    {
      pageGroupId: pageGroupId,
      uuId: state.rewriteUid,
      deploymentId,
      isControlForAll: isControlForAll,
      hasControlForAll: config.hasControlForAll,
    },
    events,
    state,
  );

  if (!values.length) {
    return;
  }

  for (const val of values) {
    sendEventToBlitz(apiKey, eventNameForBlitz, val);
  }
}

function getKrt(apiKey: string) {
  const krtName = (window as any)[`__KARTE_EDGE_${apiKey}`]?.n ?? 'krt';
  const krt = (window as any)[krtName] as KRT;
  return krt;
}

function haveEdgeTag(apiKey: string): boolean {
  // edge.js, tracker.js の読み込み順序によってkrtがundefinedになる時がある
  const krt = getKrt(apiKey);
  return typeof krt !== 'undefined' || typeof tracker !== 'undefined';
}

function sendKarteEvent(apiKey: string, eventName: string, value: KarteEventValue) {
  const krt = getKrt(apiKey);
  if (typeof krt !== 'undefined') {
    krt('send', eventName, value);
  } else if (typeof tracker !== 'undefined') {
    tracker.track(eventName, value);
  }
}

function sendEventToBlitz(apiKey: string, eventName: string, value: KarteEventValue) {
  if (!haveEdgeTag(apiKey)) {
    bufferingEvents(eventName, value);
  } else {
    try {
      sendBufferingEvents(apiKey);
      sendKarteEvent(apiKey, eventName, value);
    } catch (e) {
      window.console.error(e);
    }
  }
}

/**
 * edge.jsの読み込みの順番でkrtがundefinedになる時があるので、イベントをメモリにバッファリングする
 */
function bufferingEvents(eventName: string, value: KarteEventValue) {
  const event: EventBuffer = {
    eventName: eventName,
    value: value,
  };

  try {
    eventBuffer.push(event);
  } catch (e) {
    window.console.error(e);
  }
}

function haveBufferedEvents(): boolean {
  return !!eventBuffer.length;
}

function sendBufferingEvents(apiKey: string): boolean {
  if (!haveEdgeTag(apiKey)) {
    return false;
  }
  if (!haveBufferedEvents()) {
    return true;
  }
  try {
    for (const b of eventBuffer) {
      sendKarteEvent(apiKey, b.eventName, b.value);
    }
  } catch (e) {
    window.console.error(e);
  }
  eventBuffer = [];
  return true;
}

/**
 * pageViewをBlitzにおくる
 * pageViewは他のイベントと比べて、blitzに送るJSONのpropertyが違うので別の関数にしている
 */
function callBlitzPageViewEventSender(apiKey: string, state: State, deploymentId: string): void {
  const values = blitzPageViewEventValueConverter({ uuId: state.rewriteUid, deploymentId }, state);

  for (const val of values) {
    sendEventToBlitz(apiKey, senderModule.BLITZ_BLOCKS_EVENT_NAME_MAP.pageView, val);
  }
}

/**
 * ダイナミックブロックPV計測用イベントを送信
 */
function callBlitzDynamicBlockPageViewEventSender(
  apiKey: string,
  state: State,
  deploymentId: string,
  variationIds: string[],
): void {
  const values = blitzDynamicBlockPageViewEventValueConverter(
    { uuId: state.rewriteUid, deploymentId },
    state,
    variationIds,
  );

  for (const val of values) {
    sendEventToBlitz(apiKey, senderModule.BLITZ_BLOCKS_EVENT_NAME_MAP.dynamicBlockPageView, val);
  }
}

function createEvent(eventName: EventName, conditions: ConditionArg[]): Event[] {
  if (eventName === 'view') {
    return [
      {
        eventName,
      },
    ];
  }

  const events = conditions.map(c => {
    const patternId = c.isControlForCondition ? 'isControl' : c.patternId;
    return {
      eventName,
      patternId,
      areaId: c.areaId,
      variationId: c.variationId,
      conditionId: c.conditionId,
      hasControlForCondition: c.hasControl,
    };
  });

  return events;
}

/**
 * applyイベント送信
 * matchedConditionsArrayByPageGroupsはpageGroupsとindexが1対1対応している
 * @param pageGroups
 * @param matchedConditionsArrayByPageGroups
 * @param isInControlGroupForAll
 * @param state
 * @param config
 * @param deploymentId
 */
const sendApplyEvents = ({
  pageGroups,
  isInControlGroupForAll,
  state,
  config,
  deploymentId,
}: {
  pageGroups: Raw.PageGroup[];
  isInControlGroupForAll: boolean;
  state: State;
  config: Raw.Config;
  deploymentId: string;
}): void => {
  pageGroups.forEach(pageGroup => {
    const conditions = extractMatchedAndFormattedConditions(
      pageGroup,
      state.rewriteUid,
      state.segments,
      state.conditionVal,
      isInControlGroupForAll,
    );
    if (!conditions) {
      return;
    }
    const conditionParams = conditions
      .filter(c => !c.isOriginal)
      .map(c => {
        const pattern = extractMatchedPattern(c);
        if (!pattern) return undefined;
        return {
          conditionId: c.conditionId,
          isControlForCondition: c.isControlForCondition,
          hasControl: c.hasControl,
          patternId: pattern.patternId,
        };
      })
      .filter(p => p !== undefined) as ConditionArg[];
    // filterでundefinedを除去してるからダウンキャストは大丈夫なはず
    const applyEventParams = {
      config,
      state,
      isControlForAll: isInControlGroupForAll,
      conditions: conditionParams,
      pageGroupId: pageGroup.pageGroupId,
      deploymentId,
    };
    sendApplyEvent(applyEventParams);
  });
};

/**
 * PageGroupごとにViewイベントを送信
 * @param pageGroups
 * @param isInControlGroupForAll
 * @param config
 * @param state
 * @param deploymentId
 */
function sendPageGroupsViewEvents({
  pageGroups,
  isInControlGroupForAll,
  config,
  state,
  deploymentId,
}: {
  pageGroups: Raw.PageGroup[];
  isInControlGroupForAll: boolean;
  config: Raw.Config;
  state: State;
  deploymentId: string;
}): void {
  pageGroups.forEach(pageGroup => {
    const viewEventParams = {
      config,
      state,
      isControlForAll: isInControlGroupForAll,
      conditions: [],
      pageGroupId: pageGroup.pageGroupId,
      deploymentId,
    };
    sendViewEvent(viewEventParams);
  });
}

function sendOtherEvent({
  eventName,
  values,
  config: { apiKey },
}: {
  eventName: string;
  values: KarteEventValue;
  config: Raw.Config;
}): void {
  sendEventToBlitz(apiKey, eventName, values);
}

export {
  sendPageViewEvent,
  sendViewEvent,
  sendApplyEvent,
  sendClickEvent,
  sendImpressionEvent,
  sendSkippedAreaClickEvent,
  sendSkippedAreaImpressionEvent,
  sendApplyEvents,
  sendPageGroupsViewEvents,
  sendDynamicBlockPageViewEvent,
  sendOtherEvent,
  ConditionArg,
  SendEventArg,
  blitzEventValueConverter as blitzEventValueConverterForTest,
  blitzPageViewEventValueConverter as blitzPageViewEventValueConverterForTest,
  blitzDynamicBlockPageViewEventValueConverter as blitzDynamicBlockPageViewEventValueConverterForTest,
  sendBufferingEvents,
};
