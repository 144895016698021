export var ResolverTypes;
(function (ResolverTypes) {
    ResolverTypes["Dummy"] = "dummy";
    ResolverTypes["Template"] = "template";
    ResolverTypes["Campaign"] = "campaign";
    ResolverTypes["UserData"] = "user-data";
    ResolverTypes["PageUserCount"] = "page-user-count";
    ResolverTypes["ActionTableRow"] = "action-table-row";
    ResolverTypes["ActionTableRows"] = "action-table-rows";
    ResolverTypes["ActionTableQuery"] = "action-table-query";
    ResolverTypes["Number"] = "number";
    ResolverTypes["String"] = "string";
    ResolverTypes["Boolean"] = "boolean";
})(ResolverTypes || (ResolverTypes = {}));
