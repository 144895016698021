export const CONSTANTS = {
    MAX_UPLOAD_FILESIZE: 30 * 1024 * 1024,
};
export const DEBUG_PROJECT_API_KEYS = {
    PROD_KARTE_IN_KARTE_API_KEY: '73836f0a7f9b2161cb78d5295dfc21c7',
    EVA_BLOCKS_PROJECT_API_KEY: '06ab2066733f7dd90f2adf3fdccc858c',
    BLOCKS_DOGFOODING_API_KEY: 'e199376c76ba131d15912674b8f16f76',
    PROD_BLOCKS_DOGFOODING_API_KEY: 'd4245b7cbb76e3595a3801bc4d5a39c6',
    TEST_TOKYO_REGION_API_KEY: '086297423c7b2ba24d8f6806fe28a30c',
    EVA_TESTING_API_KEY: '1f868fe414d8f92167b7029cd3574787',
    EVA_TESTING_BLOCKS_API_KEY: '6df6760ed90f52e23ddd0e7b90f2f8bd',
};
